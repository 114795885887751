<template>

  <div class="grid">
    <div class="col-12">
      <h3>Configurações</h3>
    </div>
  </div>

  <div class="grid">

    <div class="col-12 md:col-5">

      <div class="card fluid">
        <h5>Alterar a Senha</h5>
        <form class="grid" autocomplete="no">

          <div class="md:col-6 pt-4">
            <div class="p-float-label">
              <Password
                id="senha2"
                v-model="senhaNova"
                :feedback="false"
                autocomplete="no"
                class="block field-alterar-senha"
                toggleMask
              ></Password>
              <label for="senha2">Nova Senha</label>
            </div>
          </div>

          <div class="md:col-6 pt-4">
            <div class="p-float-label">
              <Password
                id="senha3"
                v-model="senhaConf"
                :feedback="false"
                autocomplete="no"
                class="block field-alterar-senha"
                toggleMask
              ></Password>
              <label for="senha3">Confirmar Nova Senha</label>
            </div>
          </div>

          <div class="md:col-12">
            <div class="grid">
              <div class="md:col-12">
                <Button
                  id="buttonAlterarUsuario"
                  label="Alterar Usuário"
                  icon="pi pi-check"
                  class="button-success"
                  @click="alterar"
                ></Button>
              </div>
            </div>
          </div>

        </form>
      </div>

      <div class="card fluid">
        <h5>Tipo de Entrega</h5>
        
        <div class="grid">

          <div class="col-12 pt-4">
            <Dropdown
              v-model="cobrarFrete"
              :options="[{opcao: true, descricao: 'Por conta do Cliente'},{opcao: false, descricao: 'Por conta da Loja'}]"
              optionLabel="descricao"
              optionValue="opcao"
              placeholder="Selecione"
              class="w-full"
            />
          </div>

          <div class="col-12" v-if="!cobrarFrete">
            <label for="valorMinimo" class="block mb-2">Valor mínimo para gratuidade:</label>
            <InputNumber
            id="valorMinimo"
            mode="currency"
            currency="BRL"
            locale="pt-BR"
            v-model="valorMinimo"
            />
          </div>

          <div class="col-12" v-if="cobrarFrete">
            <label for="valorFixo" class="block mb-2">Valor fixo (valor zerado desabilita):</label>
            <InputNumber
            id="valorFixo"
            mode="currency"
            currency="BRL"
            locale="pt-BR"
            v-model="valorFixo"
            />
          </div>

          <div class="col-12">
            <Button
              label="Gravar Tipo de Entrega"
              icon="pi pi-check"
              class="button-success"
              @click="alterarTipoEntrega"
            ></Button>
          </div>

        </div>
      </div>

      <div class="card fluid">
        <h5>Configurações do E-Commerce</h5>
        
        <div class="grid">

          <div class="col-12 pt-4">
            <h6 class="font-semibold"><i class="pi pi-angle-right"></i> Geral:</h6>
            
            <label for="cor" class="block mb-1 font-medium">Cor:</label>
            <ColorPicker id="cor" v-model="color" />
            <InputText type="text" style="padding: 0.35rem; width: 70px; margin-left: 0.3rem" v-model="color" />

          </div>

          <div class="col-12 pt-4">
            <h6 class="font-semibold"><i class="pi pi-angle-right"></i> Página Inicial - Destaque:</h6>

            <label for="colunasPorLinhaWebDestaque" class="block mb-1 font-medium">Colunas por linha (Computador):</label>
            <InputNumber
              id="colunasPorLinhaWebDestaque"
              :step="1"
              :min="1"
              :max="4"
              :showButtons="true"
              v-model="colunasPorLinhaWebDestaque"
            />

            <label for="colunasPorLinhaMobileDestaque" class="block mb-1 mt-3 font-medium">Colunas por linha (Celular):</label>
            <InputNumber
              id="colunasPorLinhaMobileDestaque"
              :step="1"
              :min="1"
              :max="3"
              :showButtons="true"
              v-model="colunasPorLinhaMobileDestaque"
            />

            <label for="itensScrollDestaque" class="block mb-1 mt-3 font-medium">Ítens carregados por rolagem:</label>
            <InputNumber
              id="itensScrollDestaque"
              :step="1"
              :min="1"
              :max="9999"
              :showButtons="true"
              v-model="itensScrollDestaque"
            />

            <label for="isCarrocelDestaque" class="block mb-1 mt-3 font-medium">Apresentação em Carrocel:</label>
            <InputSwitch 
              id="isCarrocelDestaque" 
              v-model="isCarrocelDestaque"
            />

          </div>

          <div class="col-12 pt-4">
            <h6 class="font-semibold"><i class="pi pi-angle-right"></i> Página Inicial - Produtos:</h6>

            <label for="colunasPorLinhaWebProduto" class="block mb-1 font-medium">Colunas por linha (Computador):</label>
            <InputNumber
              id="colunasPorLinhaWebProduto"
              :step="1"
              :min="1"
              :max="4"
              :showButtons="true"
              v-model="colunasPorLinhaWebProduto"
            />

            <label for="colunasPorLinhaMobileProduto" class="block mb-1 mt-3 font-medium">Colunas por linha (Celular):</label>
            <InputNumber
              id="colunasPorLinhaMobileProduto"
              :step="1"
              :min="1"
              :max="3"
              :showButtons="true"
              v-model="colunasPorLinhaMobileProduto"
            />

            <label for="itensScrollProduto" class="block mb-1 mt-3 font-medium">Ítens carregados por rolagem:</label>
            <InputNumber
              id="itensScrollProduto"
              :step="1"
              :min="1"
              :max="9999"
              :showButtons="true"
              v-model="itensScrollProduto"
            />

            <label for="isCarrocelProduto" class="block mb-1 mt-3 font-medium">Apresentação em Carrocel:</label>
            <InputSwitch 
              id="isCarrocelProduto" 
              v-model="isCarrocelProduto"
            />

          </div>

          <div class="col-12 pt-4">
            <Button
              label="Gravar Configurações"
              icon="pi pi-check"
              class="button-success"
              @click="alterarConfiguracoes"
            ></Button>
          </div>

        </div>
      </div>

    </div>

    <div class="col-12 md:col-7">

      <div class="card fluid">
        <h5>Sobre a Empresa</h5>

        <Textarea v-model="sobre" :autoResize="true" rows="10" class="w-full mb-3" />

        <Button
          id="buttonAlterarSobre"
          label="Gravar"
          icon="pi pi-check"
          class="button-success"
          @click="alterarSobre"
        ></Button>

      </div>

    </div>

  </div>

  <Toast position="top-right" />

</template>

<script>

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

export default {
  data() {
    return {
      // Campos do Formulário
      senhaNova: "",
      senhaConf: "",
      sobre: "",
      cobrarFrete: true,
      valorMinimo: 0,
      valorFixo: 0,
      color: "144DB3",
      itensScrollDestaque: 2,
      colunasPorLinhaWebDestaque: 2,
      colunasPorLinhaMobileDestaque: 1,
      isCarrocelDestaque: false,
      itensScrollProduto: 4,
      colunasPorLinhaWebProduto: 4,
      colunasPorLinhaMobileProduto: 2,
      isCarrocelProduto: false,
    };
  },

  mounted() {

    const self = this;
    const user = firebase.auth().currentUser;

    firebase.database().ref("Usuarios").child(user.uid).child("sobre").once("value", function(snapshot) {
      if(snapshot.exists())
        self.sobre = snapshot.val();
    });

    firebase.database().ref("Usuarios").child(user.uid).child("cobrarFrete").once("value", function(snapshot) {
      if(snapshot.exists())
        self.cobrarFrete = snapshot.val();
      else
        self.cobrarFrete = true;
    });

    firebase.database().ref("Usuarios").child(user.uid).child("valorMinimoFrete").once("value", function(snapshot) {
      if(snapshot.exists())
        self.valorMinimo = snapshot.val();
      else
        self.valorMinimo = 0;
    });

    firebase.database().ref("Usuarios").child(user.uid).child("valorFixoFrete").once("value", function(snapshot) {
      if(snapshot.exists())
        self.valorFixo = snapshot.val();
      else
        self.valorFixo = 0;
    });

    this.$api.get("/empresas/configuracoes").then((res) => {
      if(res.data.success) {
        self.color = res.data.data.cabecalho.color;
        self.itensScrollDestaque = res.data.data.destaques.itensScroll;
        self.colunasPorLinhaWebDestaque = res.data.data.destaques.colunasPorLinhaWeb;
        self.colunasPorLinhaMobileDestaque = res.data.data.destaques.colunasPorLinhaMobile;
        self.isCarrocelDestaque = res.data.data.destaques.isCarrocel;
        self.itensScrollProduto = res.data.data.produtos.itensScroll;
        self.colunasPorLinhaWebProduto = res.data.data.produtos.colunasPorLinhaWeb;
        self.colunasPorLinhaMobileProduto = res.data.data.produtos.colunasPorLinhaMobile;
        self.isCarrocelProduto = res.data.data.produtos.isCarrocel;
      }
    });

  },

  methods: {

    alterar() {
      const self = this;

      var erro = false;

      //Alteração de senha
      if (self.senhaNova != "") {
        //Checar se senhas digitadas são iguais
        if (self.senhaNova != self.senhaConf) {
          self.$toast.add({
            severity: "error",
            summary: "ERRO!",
            detail: "A Nova Senha e Confirmação da Nova Senha são diferentes!",
            life: 5000,
          });
          erro = true;
        }
      }

      if (erro) return false;

        const user = firebase.auth().currentUser;
        user.updatePassword(self.senhaNova).then(() => {
        
            firebase.database().ref("Usuarios").child(user.uid).update({
                "senha": self.novaSenha,
            }, () => {

                self.$toast.add({
                  severity: "success",
                  summary: "Sucesso!",
                  detail: "Senha alterada com sucesso!",
                  life: 3000,
                });

            });

        }).catch((error) => {

            const errorCode = error.code;
            const errorMessage = error.message;
            
            self.$toast.add({
                severity: "error",
                summary: "ERRO: " + errorCode,
                detail: errorMessage,
                life: 5000,
            });
        });

    },

    alterarSobre() {
      const user = firebase.auth().currentUser;
      const self = this;
      firebase.database().ref("Usuarios").child(user.uid).update({
        "sobre": self.sobre,
      }, () => {

        self.$toast.add({
          severity: "success",
          summary: "Sucesso!",
          detail: "Sobre a Empresa alterado com sucesso!",
          life: 3000,
        });

      });
    },

    alterarTipoEntrega() {
      const user = firebase.auth().currentUser;
      const self = this;
      firebase.database().ref("Usuarios").child(user.uid).update({
        "cobrarFrete": self.cobrarFrete,
        "valorMinimoFrete": self.valorMinimo,
        "valorFixoFrete": self.valorFixo
      }, () => {

        self.$toast.add({
          severity: "success",
          summary: "Sucesso!",
          detail: "Tipo de Entrega alterado com sucesso!",
          life: 3000,
        });

      });
    },

    alterarConfiguracoes() {

      const self = this;

      const dados = {
        "cabecalho": {
          "color": this.color
        },
        "destaques": {
          "colunasPorLinhaWeb": this.colunasPorLinhaWebDestaque,
          "colunasPorLinhaMobile": this.colunasPorLinhaMobileDestaque,
          "itensScroll": this.itensScrollDestaque,
          "isCarrocel": this.isCarrocelDestaque
        },
        "produtos": {
          "colunasPorLinhaWeb": this.colunasPorLinhaWebProduto,
          "colunasPorLinhaMobile": this.colunasPorLinhaMobileProduto,
          "itensScroll": this.itensScrollProduto,
          "isCarrocel": this.isCarrocelProduto
        }
      };
        
      firebase.database().ref("VipDelivery/ConfiguracoesEcommerce")
      .child(firebase.auth().currentUser.uid).update(dados, () => {

        self.$toast.add({
          severity: "success",
          summary: "Sucesso!",
          detail: "Configurações do E-Commerce alterados com sucesso!",
          life: 3000,
        });

      });

    }

  },
};
</script>

<style type="text/css">

  .field-alterar-senha input {
    width: 100%;
  }

</style>